import { Box, CTA, Low, Separator, Status } from './Card.Content.style'
import { useBreak }  from 'hooks/useBreak'
import { get }       from 'components/List/List.Utilities'
import { Text }      from './Card.Text'
import { Thumbnail } from './Card.Content.Thumbnail'
import { Icon }      from 'assets/Icons_'
import { useAtom }   from 'state/jotai'
import { favorites } from 'state/store.global'
import { compares }  from 'state/store.global'

export const Content = ({unit,thumbnail,color}) => {
  
  const likes    = useAtom(favorites)[0]
  const setLikes = useAtom(favorites)[1]
  const comps    = useAtom(compares)[0]
  const setComps = useAtom(compares)[1]
  const show     = useBreak('card').toString()
  const sID      = unit.Data.UnitStatusID.Value

  const isMobile = useBreak('md_dn')
  
  const share      = { icon:'share'      , color:'#000'    , size:isMobile?15:15 }
  const compare    = { icon:'compare3'   , color:'#000'    , size:isMobile?25:25 }
  const compare_   = { icon:'compare3'   , color:'gray'    , size:isMobile?25:25 }
  const heartempty = { icon:'heart_empty', color:'#000'    , size:isMobile?28:28 }
  const heartfull  = { icon:'heart_full' , color:'#863E30' , size:isMobile?28:28 }

  const isLiked = likes.includes(unit.UnitID)
  const isComps = comps.includes(unit.UnitID)

  const toggleLike = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...likes,unit.UnitID]
    const rem = [...likes].filter( i => i !== unit.UnitID )
    setLikes( isLiked ? rem : add )
    log({ action:isLiked 
            ? 'removed_apartment_from_favorites'
            : 'added_apartment_to_favorites'
          , unitId })
  }

  const toggleComp = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...comps,unit.UnitID]
    const rem = [...comps].filter( i => i !== unit.UnitID )
    const max = comps.length == 3
    setComps( isComps ? rem : ( max ? comps : add ) )
  }

  return (
    <>
      { show == 'true' && <Thumbnail thumbnail={thumbnail}/> }
      <Box>
        <Text unit={unit}/>
        {/* <div style={{height:12}}/> */}
        <Separator type={show} color={color}/>
        {/* <div style={{height:8}}/> */}
        <Low type={show}>
          <CTA onClick={toggleComp}>
            <Icon {... isComps ? compare_ : compare } count={ isComps ? comps.indexOf(unit.UnitID)+1 : 0}/>
            { isComps ? 'Valgt' : 'Sammenlign' }
          </CTA>
          <CTA onClick={toggleLike}>
          {
            show == 'true' ? <>
              <Icon {... isLiked ? heartfull : heartempty}/>{ isLiked ? 'Din favorit' : 'Gem bolig' }
            </> : <>
              <Icon {...isLiked ? heartfull : heartempty}/>{ isLiked ? 'Din favorit' : 'Gem bolig' }
            </>
          }
          </CTA>
        </Low>
        <Status color={get(unit,'UnitColor')}>{ get(unit,'UnitStatusName') }</Status>
      </Box>
    </>
  )
}