import { Box, Grid } from "@mui/material";
import React from "react";
import CompareUnitCard from "./CompareUnitCard";
import PropertiesGroup from "./PropertiesGroup";
import useCompareUnits from "./useCompareUnits";

const Compare = () => {
  const { units, properties } = useCompareUnits();

  return (
    <Box sx={{ minWidth: { lg: 1200, md: 1000, sm: 750, xs: 500 }, overflowX: "auto", }} >
      <Grid container spacing={0.125}>
        {units.map((unit, i) => {
          return (
            <Grid item key={i} xs={4}>
              <CompareUnitCard unit={unit} />
            </Grid>
          );
        })}
      </Grid>

      {/* properties */}
      <Box sx={{ mt: 5 }}>
        {properties.map((properties, i) => {
          return (
            <Box key={i} sx={{ py: 3 }}>
              <PropertiesGroup groupTitle={properties.title} unitsProperties={properties.data} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Compare;
