import { active_project }         from 'state/store.global'
import { vis_filter, vis_detail } from 'state/store.global'
import { vis_desktop_nav }        from 'state/store.global'
import { useGet }                 from 'state/jotai'
import { Content, Root }          from './Layout.style'
import { DualMenu }               from 'components/DualMenu/DualMenu'
import { TopMenu }                from 'components/TopMenu/TopMenu'

export const Layout = ({ children }) => {
  
  const { Navigation, DrawersLeft, DrawersRight, Views } = children
  
  const pin   = useGet(active_project).pin
  const left  = useGet(vis_filter)
  const right = useGet(vis_detail)
  const nav   = useGet(vis_desktop_nav)

  return (
    <Root>
      { pin >= 0 && DrawersLeft.map(i => i) }
      { pin >= 0 && DrawersRight.map(i => i) }
      <Content left={left} right={right} nav={nav}>
        { Views }
      </Content>
      <TopMenu left={left} right={right} nav={nav}/>
      <DualMenu left={left} right={right} nav={nav}/>
      { Navigation }
    </Root>
  )
}