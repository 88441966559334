import { Outlet }              from 'react-router-dom'
import { Hotkeys }             from 'hotkeys'
import { useSetActiveProject } from 'hooks/useSetActiveProject'

export const Root = () => {

  useSetActiveProject()

  return (
    <>
      <Hotkeys/> { /* Hotkeys are added here so that they can access the react router API */ }
      <Outlet/> { /* Outlet is where all Views are rendered */ }
    </>
  )
}