import styled from 'styled-components'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Legend = styled(motion.div)`
  max-width: 100vw;
  width: 424px;
  background-color: #ffffff;
  padding: 30px 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  bottom:0;
  left:50%;
  position: fixed;
  border: 1px solid rgba(0, 0, 0, 0.1);
  ${ BREAK_.md_up } {
    /* top:0; */
    left:calc(50% + 50px);
  }
`
export const Dot = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  /* border:1px solid black; */
`
export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding:0 5px;
  /* border:1px solid black; */
`
export const Txt = styled.div`
  line-height:1;
  white-space:nowrap;
  font-size:0.6875rem;
  /* border:1px solid black; */
`