import { Button, Stack } from "@mui/material";
import { useContactDrawer } from "components/Contact/useContactDrawer";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toggle_drawer } from "state/actions";
import { useAtom } from "state/jotai";
import Unit from "./Unit.model";

function DrawerCTA({ unit }) {
  const { open } = useContactDrawer();

  const navigateTo = useNavigate();

  const {
    i18n: { language },
  } = useTranslation();
  const [, toggleDrawer] = useAtom(toggle_drawer);

  const handleMore = () => {
    navigateTo(`/${language}/unit/${unit.getId()}`);
    toggleDrawer();
  };

  const handleContact = () => {
    open(unit);
  }

  return (
    <Stack direction="row" spacing="1px" justifyContent="center">
      <Button sx={{background:"#E98B8D","&:hover": {background: "#CF7F7F"}}} variant="contained" size="large" onClick={handleContact}>
        Kontakt
      </Button>
      <Button sx={{background:"#E98B8D","&:hover": {background: "#CF7F7F"}}} variant="contained" size="large" onClick={handleMore}>
        Læs mere
      </Button>
    </Stack>
  );
}

DrawerCTA.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default DrawerCTA;
