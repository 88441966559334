import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { ButtonCompare, ButtonFavorite, ButtonShare } from ".";

function Buttons({ unitId }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      sx={{ bgcolor: "#f3f3f3", py: { md: 1, xs: 0.5 } }}
    >
      <ButtonShare unitId={unitId} />
      <ButtonCompare unitId={unitId} />
      <ButtonFavorite unitId={unitId} />
    </Stack>
  );
}

Buttons.propTypes = {
  unitId: PropTypes.number.isRequired,
};

export default Buttons;
