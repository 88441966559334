
import { useState } from 'react'
import { Backdrop } from 'styles/styles.backdrop'
import { useAtom } from 'state/jotai'
import { Button, Stack } from '@mui/material'
import { STATICS } from 'api/api'
import { Icon } from 'assets/Icons_'
import { vis_calculate_benefit } from 'state/store.global'
import { housing_benefit } from 'state/store.global'
import { gross_income } from 'state/store.global'
import { Container, Inner, Result, SubText, CurrencyInput } from './HousingBenefit.style'
import './HousingBenefit.css'
import { useHousingBenefit } from './HousingBenefit.hook'

const data = {
  Header1: 'Beregn din boligstøtte',
  Text1  : 'Indtast din husstands årlige bruttoindtægt (månedsløn før skat x 12), og få lavet en vejledende beregning for din månedlige boligstøtte',
  Header2: 'Husstandens årlige bruttoindtægt',
  Header3: 'Din vejledende boligstøtte',
  SubText: 'Den boligstøtte beregning du kan se her, er vejledende og vi giver ingen garanti i forhold til den endelige boligstøtte du måtte kunne få. Brug eventuelt https://www.boligstoette.dk/ som har en beregner der stilles til rådighed af Udbetaling Danmark.',
}

export const BenefitPortal = () => {
  const [show, setShow]      = useAtom(vis_calculate_benefit)
  const [benefit,setBenefit] = useAtom(housing_benefit)
  const [income,setIncome]   = useAtom(gross_income)
  const [val, setVal]        = useState('')
  
  const change = e => {
    setIncome({
      UnformattedValue:e.target.value.replace(/,|\.| kr\./g,''),
      Value:e.target.value
     } )
  }

  const output = useHousingBenefit()

  const icon = {
    icon: 'close6',
    color: '#fff',
    wrap: { position: 'absolute', top: 20, right: 20 },
    size: '20',
    click: () => setShow(false),
  }

  return (
    <>
      <Backdrop
        onClick={() => {
          setShow(false)
        }}
      />
      <Container>
        <img src={`${STATICS}generic.png`} alt='' />
        <Icon {...icon} />
        <Inner>
          <h2>{data.Header1}</h2>
          <p>{data.Text1}</p>
          <div style={{ height: 40 }} />
          <h2>{data.Header2}</h2>
          {/* <Input type='text' onChange={change}></Input> */}
          <CurrencyInput
            onChange={change}
            placeholder={income.Value}
          />
          <div style={{ height: 30 }} />
          <h2>{data.Header3}</h2>
          <Result>{output}</Result>
          
          <SubText>{data.SubText}</SubText>
          <Stack direction='row' spacing='1px' justifyContent='center'>
            <Button variant='contained' size='large' onClick={() => setShow(false)}>
              Gem din beregning
            </Button>
          </Stack>
        </Inner>
      </Container>
    </>
  )
}
