import { Button, Menu } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useState } from "react";

const Dropdown = forwardRef(({ children, buttonText }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  // Expose child method through the ref
  useImperativeHandle(ref, () => ({
    closeMenu() {
      setAnchorEl(null);
    },
  }));

  return (
    <>
      <Button onClick={handleOpenMenu} sx={{ color: "#000000", fontWeight: 400, fontSize: 16, px: 0 }} endIcon={ 
        <Icon icon="caretDown" sx={{ fontSize: "10px !important", mt: "0.5em", pointerEvents: "none", color: "text.primary", transform: open ? "rotate(180deg)" : "rotate(0)", transition: "transform 0.3s", }} /> } >
        {buttonText}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu} sx={{ "& .MuiList-root": { py: "18px", }, "& .MuiMenuItem-root": { fontSize: 16, py: "16px", px: "20px", "&:hover": { color: "primary.main", backgroundColor: "rgba(217, 217, 217, 0.35)", }, }, }} PaperProps={{ elevation: 3, sx: { ml: { xs: "16px", md: "0" }, backgroundColor: "rgba(249, 249, 249, 0.95)", width: 400, maxWidth: "100%", maxHeight: 400, "&::-webkit-scrollbar": { display: "none" }, scrollbarWidth: "none", }, }} >
        {children}
      </Menu>
    </>
  );
});

Dropdown.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
};

Dropdown.defaultProps = {
  buttonText: "",
};

Dropdown.displayName = "Dropdown";

export default Dropdown;
