import { useState, useEffect } from 'react'
import { _project } from 'state/store.projects'
import { get } from 'state/jotai'
import { Legend, Dot, Status, Txt, Group, Header } from './ColorLegendPopup.style'
import { motion } from 'framer-motion'
import { useBreak } from 'hooks/useBreak'

export const ColorLegendPopup = () => {
  const [comb, setComb] = useState()

  const raw = get(_project, 'unitsraw', 'Units')

  useEffect(() => {
    if (raw.length) {
      setComb([
        ...new Set(
          raw.map(i => {
            return `${
              i.Data.UnitStatusName.Value
            }___${i.Data.UnitColor.Value.toLowerCase()}`
          })
        ),
      ])
    }
  }, [raw])

  const isDesktop = useBreak('md_up')

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, ease: 'linear' },
  }

  if (raw.length && comb)
    return (
      <>
        <Legend {...animation}>
          <Header>Hvad betyder de forskellige farver?</Header>
          <Group>
            {comb.map(i => {
              const name = i.split('___')[0]
              const color = i.split('___')[1]
              return (
                <Status key={i}>
                  <Dot style={{ background: color }} />
                  <Txt children={name} />
                </Status>
              )
            })}
          </Group>
        </Legend>
      </>
    )
}
