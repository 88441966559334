import { useFetch_Highlights } from "hooks/fetch/useFetch_Highlights";
import { useGetUnit } from "hooks/useGetUnit";
import { useAtom } from "state/jotai";
import { active_unit } from "state/store.global";
import Unit from "./Unit.model";
import { applyfilters } from 'utilities/utility.filter'
import { getProjectData } from "state/jotai.projectDataHooks";

const useActiveUnit = () => {
  const [unit] = useAtom(active_unit);
  return new Unit(unit);
};

const useUnitById = (unitId) => {
  const unit = useGetUnit(unitId);
  return new Unit(unit);
};

const useRelatedUnits = (unitId) => {
  const unit = useGetUnit(unitId);
  
  const raw = getProjectData('unitsraw', 'Units')

  const area  = unit?.Data?.Areal?.UnformattedValue
  const price = unit?.Data?.Pris?.UnformattedValue

  const filter = {
    interval: [
      { property: 'Pris', min: price - 1000, max: price + 1000 },
      { property: 'Areal', min: area - 5, max: area + 5 },
    ],
  }

  const relatedUnits = applyfilters(raw, filter)
    .filter(i => i.UnitID !== unitId)
    .slice(-4)
    .map( i => new Unit(i))

  return relatedUnits;
};

const useUnitHighlight = (unit) => {
  const { highlights } = useFetch_Highlights();
  return highlights.map((hl) => {
    return {
      ...hl,
      value: unit.getValue(hl.property),
      postfix: unit.getPostfix(hl.property),
    };
  });
};

export { useActiveUnit, useUnitById, useUnitHighlight, useRelatedUnits };
