import { useEffect }      from 'react'
import { useFetch }       from 'hooks/fetch/useFetch'
import { useSetAtom }     from 'state/jotai'
import { _bootstrap}      from 'state/store.bootstrap'
import { BOOT }           from 'api/api'
import { useTranslation } from 'react-i18next'

export const useFetch_Bootstrap = () => {
  
  const { i18n:{language} } = useTranslation()
  
  const { D:data } = useFetch({url:BOOT,file:'useFetch_Bootstrap.jsx'})
  const state = Object.keys(data || {}).length ? true : false
  
  const updateAtom = useSetAtom(_bootstrap)

  useEffect(()=>{
    if ( data ) {
      updateAtom({
        type         : data.HasMap ? 'poly' : 'mono',
        cdn          : data.CDNIsometry,
        openhouse    : data.ShowOpenHouseInfo,
        siteid       : data.SiteID,
        sitename     : data.SiteName,
        routes       : data.Router,
        startpage    : data.StartPage ?? `${language}/isometry`,
        dropdown     : data.Dropdown,
        projectids   : data.ProjectIDs,
        projectnames : data.ProjectNames,
      })
    }
  },[data])

  return state

}