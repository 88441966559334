import { useState } from 'react'
import { _project } from 'state/store.projects'
import { _isometry } from 'state/store.isometry'
import { active_unit } from 'state/store.global'
import { useGet, get } from 'state/jotai'

import { useAtom } from 'jotai'

import { Loading2 } from 'components/Loading2/Loading2'
import { getpicks } from 'utilities/utility.misc'

import { Table } from './Table/Table'
import { Grid } from './Grid/Grid'
import { Card } from './Card/Card'

import { Caption } from './List.Caption'
import { Scroll } from './List.Scroll'
import { Content, Bottom } from './List.style'
import { useClick } from './List.click'

export const List = () => {
  const [p] = useAtom(_project)

  const pick = { background: '#ff00ff' }
  // const unit         = useGet(active_unit)
  const units = get(_project, 'units', 'Units', false)
  const raw = get(_project, 'unitsraw', 'Units')
  const text = get(_project, 'cardtext')
  const picks = get(_project, 'handpicked')
  const extra = getpicks(raw, picks)
  const count = units?.length ?? 0
  const total = raw?.length ?? 0
  const mode = useState('card')
  const click = useClick(units)
  const caption = { count, mode: mode[0], setMode: mode[1], total }
  const list = { units, extra, text, pick, click }

  return (
    <>
      <div style={{ height: 120 }} />
      <Caption {...caption} />
      <div style={{ height: 10 }} />
      <div style={{ border: '0px solid red' }}>
        {units ? (
          <Content>
            {/* { mode[0] === 'table' && <Table {...list}/> } */}
            {mode[0] === 'card' && <Card {...list} />}
            {mode[0] === 'grid' && <Grid {...list} />}
            <Bottom>Powered by Estatetool, © 2022</Bottom>
          </Content>
        ) : (
          <div style={{border:'0px solid black',height:'calc(100vh - 200px)'}}>
            <Loading2 show />
          </div>
        )}
      </div>
      <div style={{ height: 15 }} />
    </>
  )
}
