import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const PropertiesRow = ({ items }) => {
  const values = items.map((item) => item.Value);

  return (
    <Grid container spacing={0.125}>
      {items.map((item, i) => {
        const label = item.Label;
        const value = item.Value === true ? "Ja" : item.Value === false ? "Nej" : item.Value;
        const postfix = item.Postfix ?? "";
        const isDifferent = values.reduce( (acc, val) => (val === item.Value ? acc + 1 : acc), 0 ) === 1;
        return (
          <Grid item key={i} xs={4}>
            <Box sx={{ px: 3, py: 2, lineHeight: 1.5, borderRight: "1px solid rgba(0,0,0,0.05)", backgroundColor: isDifferent ? "#f7f7f7" : "transparent", }} >
              <Box sx={{ fontWeight: "bold" }}>{label}</Box>
              <Box>
                {value}
                { 'Kontakt mægler' === value ? '' : postfix}
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

PropertiesRow.propTypes = {
  items: PropTypes.array.isRequired,
};

export default PropertiesRow;
