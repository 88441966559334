import { useUnitById } from "components/Unit/hooks";
import { useGet } from "state/jotai";
import { compares } from "state/store.global";

const useCompareUnits = () => {
  const items = useGet(compares);

  const units = items.reduce((units, item) => {
    const unit = useUnitById(item);
    if(unit.getId()){
      units.push(unit);
    }
    return units;
  }, []);

  // grouped data for each units
  const properties = [];

  units.forEach((unit) => {
    const unitProps = unit.getProperties(true);
    unitProps.forEach((prop, ip) => {
      if (!properties[ip]) { properties[ip] = { title: prop.title, data: [], }; }
      properties[ip].data.push(prop.data);
    });
  });

  return { units, properties };
};

export default useCompareUnits;
