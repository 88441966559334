import styled from 'styled-components/macro'
import { useState } from 'react'
import Spinner from 'react-spinners/BeatLoader'
import { BREAK_ } from 'constants'

export const Thumbnail = ({ thumbnail }) => {
  const [loading, setLoading] = useState(true)
  const imageLoaded = () => setLoading(false)

  return (
    <>
      <Section>
        <img
          style={{ display: loading ? 'none' : 'unset' }}
          src={thumbnail}
          alt=''
          onLoad={imageLoaded}
        />
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner color={'#fff'} size={20} />
          </div>
        )}
      </Section>
      <div style={{minWidth:10}}></div>
    </>
  )
}

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 0.8125rem;
  flex:0 0 260px;
  justify-content:center;
  ${ BREAK_.md_dn } {
    flex: 0 0 200px;
  }
  /* margin-right:10px; */
`