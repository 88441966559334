import { useEffect }                from 'react'
import { _bootstrap }               from 'state/store.bootstrap'
import { _project }                 from 'state/store.projects'
import { useFetch }                 from 'hooks/fetch/useFetch'
import { useFetch_Units }           from 'hooks/fetch/useFetch_Units'
import { useGet, setFocus }         from 'state/jotai'
import { PROJECT, PAGE, DOWNLOADS } from 'api/api'
import { initialsort as isr }       from 'utilities/utility.sort'
import { useTranslation }           from 'react-i18next'

export const ProjectData_Mono = () => {

  const { i18n:{language} } = useTranslation()

  const type  = useGet(_bootstrap).type
  const pid   = useGet(_bootstrap).projectids?.join(',') || null

  const mono  = type === 'mono'
  const file  = mono ? 'main/ProjectData.jsx' : ''

  const url1 = pid ? PROJECT(pid,language)    : null
  const url2 = pid ? DOWNLOADS(pid)  : null
  const url3 = pid ? PAGE(pid,1080,language)  : null
  
  const project    = useFetch({ url:mono?url1:null, file }).D
  const downloads1 = useFetch({ url:mono?url2:null, file }).D
  const gallery    = useFetch({ url:mono?url3:null, file }).D
    
  useFetch_Units({ pid:mono?pid:null, pin:0 })

  const setCardtext      = setFocus(_project, 'cardtext')
  const setStatustypes   = setFocus(_project, 'statustypes')
  const setUnittypes     = setFocus(_project, 'unittypes')
  const setSorteroptions = setFocus(_project, 'sorteroptions')
  const setFilteroptions = setFocus(_project, 'filteroptions')
  const setDefaultSort   = setFocus(_project, 'activesorting')
  const setOpenhouse     = setFocus(_project, 'openhouse')
  const setGallery       = setFocus(_project, 'gallery')
  const setDownloads     = setFocus(_project, 'downloads')

  useEffect(()=>{
    if ( mono && project && downloads1 && gallery ) {

      setCardtext     ([{ [pid] : project.config.ShowInListView   }])
      setStatustypes  ([{ [pid] : project.config.UnitStatuses     }])
      setUnittypes    ([{ [pid] : project.config.SaleAndUnitTypes }])
      setFilteroptions([{ [pid] : project.config.Filters          }])
      setSorteroptions([{ [pid] : project.config.SortOrders       }])
      setDefaultSort  ([{ [pid] : isr(project.config.SortOrders)  }])
      setOpenhouse    ([{ [pid] : project.OpenHouse               }])
      setGallery      ([{ [pid] : gallery.Components[1]           }])
      setDownloads    ([{ [pid] : downloads1                      }])
    }
  },[mono, project, downloads1, gallery])
}