import styled   from 'styled-components/macro'
import { data } from 'components/Favorites/Card/Card.data'
import { form } from 'components/Favorites/Card/Card.data'
import { BREAK_ } from 'constants'

export const Text = ({unit}) => {
  
  const type = 'A'
  
  return (
    <UnitInfo>
      {
        data[type].map( line => {
          const d = form(unit,line)
          if ( unit.Data['Price_text']?.Value && line.properties[0] == 'Pris' ) return ''
          if(d=== "") return null
          return <Line key={line.key}>{ d }</Line>
        })
      }
    </UnitInfo>
  )

}

const Line = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  :nth-child(1) { font-weight: 600; }
  /* border: 1px solid black; */
  /* outline: 1px solid blue; */
`

export const UnitInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
  /* border: 1px solid black; */
  padding-top:2px;
  padding-left:12px;
  justify-content: center;
  height: 132px;
  /* ${ BREAK_.md_dn } { height: 100px; } */
`