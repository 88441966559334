import { useAtom, getFocus }        from 'state/jotai'
import { _bootstrap }               from 'state/store.bootstrap'
import { iso_free }                 from 'state/store.global'

import { Icon }                     from 'assets/Icons_'

import { Box, FullWidth, Section }   from './DualMenu.style'  

import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation }           from 'react-i18next'

export const DualMenu = ({left,right,nav}) => {

  const navigate  = useNavigate()
  const location1 = useLocation().pathname.split('/')[1]
  const here      = useLocation().pathname
  const type      = getFocus(_bootstrap, 'type')
  const rmode     = useAtom(iso_free)[0]
  
  const { i18n:{language} } = useTranslation()

  const clickList   = () => navigate(type == 'mono' ? `/${language}/list`     : `/${location1}/list`)
  const clickModel  = () => navigate(type == 'mono' ? `/${language}/isometry` : `/${location1}/isometry`)
  
  const list   = { size:22, icon:'list'   , color: here.includes('list') ? '#fff' : '#ffffff80' }
  const model  = { size:28, icon:'model'  , color: here.includes('isometry') ? '#fff' : '#ffffff80' }
  
  return (
    <FullWidth left={left} right={right} nav={nav}>
      <Box clr={clr}>
        <Section 
          clr     = { clr }
          active  = { here.includes('isometry') }
          onClick = { clickModel }
        >
          <Icon {...model} />Model
        </Section>
        <Section 
          clr     = { clr }
          active  = { here.includes('list') }
          onClick = { clickList }
        >
          <Icon {...list} />Boligliste
        </Section>
      </Box>
    </FullWidth>
  )
}