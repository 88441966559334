import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { useAtom } from 'state/jotai'
import { drawer_animation_done } from 'state/store.global'
import { get } from "components/Favorites/Favorites.Utilities";

function UnitDrawer() {
  const unit = useActiveUnit();
  const highlights = useUnitHighlight(unit);

  const unitId = parseInt(unit.getId());
  const status = unit.getStatus();
  const address = unit.getAddress();
  const images = unit.getImages();
  const isRent = unit.isRent();
  const price = unit.getPrice();
  const Pris = unit.getPrice();

  const isDrawerAnimating = useAtom(drawer_animation_done)[0]
  const availableUnits = unit?.data?.Data?.UnitStatusID?.Value === 10;
  const availablePris = unit?.data?.Data?.Pris_Tekst_DK?.Value === "";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        pb: 5,
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <TopDrawer
        address={address}
        statusText={status.label}
        backgroundColor={status.color}
        textColor="#ffffff"
      />

      { isDrawerAnimating && <Gallery images={images} /> }

      <Buttons unitId={unitId} />

      <OpenHouse />

      <Box sx={{ px: 2.5, mt: { md: 3.5, xs: 2 } }}>
        <Box sx={{ mb: { md: 3.5, xs: 2 }, mx: "auto", maxWidth: 320 }}>
          <Highlights items={highlights} />
        </Box>

        { (availablePris && price) &&
            <Box
            sx={{
              mb: { md: 3, xs: 2 },
              textAlign: "center",
            }}
          >
            <Price
              label={isRent
                ? `${get(unit.data, "LejelejlighedsText")}`
                : `Kontantpris`}
              value={price}
            />
          </Box>
          }
        <DrawerCTA unit={unit} />
      </Box>
    </Box>
  );
}

export default UnitDrawer;
