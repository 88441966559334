export const includes = (string, substrings) =>
  ( substrings.some( i => string.includes(i) ) ) 
    ? true 
    : false

export const array = (num, fnc, snp = false) =>
  Array.from(Array(num), (_,x) => fnc(x) )

export const fn3 = (a, pin, pid, input) => {
  return a?.map((i, j) => (j === pin ? { [pid]: input } : i))
}

export const fn4 = (a, pin, pid, sub, input) => a?.map((i, j) => {
  // const is = i[pid] ?? false
  // if ( is ) return 
  // clog( i, i[pid], pid )
  const x = [ ...i[pid].filter( i => i.NAME !== input.NAME), input ]
  const y = j === pin ? { [pid]: x } : i
  return y
})

export const getpicks = ( raw, picks ) => 
  raw.filter(i => picks?.includes(i?.Data?.UnitID?.UnformattedValue ))
  
export const framereducer = (num, fnc, snp, start, end) => {
  const q = Array.from(Array(num), (_,x) => x + FIRST_FRAME)
  
  if ( snp ) {
    // console.log( 'q', q ) 
    // console.log( 'SNP', snp )

    // THE FIRST FRAME IS
    const v_1         = 2
    const FIRST_FRAME = q[0]
    const LAST_FRAME  = q[q.length - 1]
    const EVEN_OR_ODD = ((FIRST_FRAME % 2 === 0) || v_1 > 2 ) ? 'EVEN' : 'ODD'
    const NEW_LST = []

    if ( EVEN_OR_ODD == 'EVEN' ) {
      q.forEach((i,j)=>{
        if ( j % v_1 == 0 ) NEW_LST.push( q[j] )
      })
    } else {
      q.forEach((i,j)=>{
        if ( j % v_1 != 0 ) NEW_LST.push( q[j] )
      })
    }

    // ADD SNAPS BACK IN
    const SNP_LST0 = [...NEW_LST,...snp ]
    const SNP_LST1 = [...new Set(SNP_LST0)].sort()

    clog(
      'FIRST_FRAME: ' + FIRST_FRAME + '\n' + 
      'LAST_FRAME:  ' + LAST_FRAME  + '\n' +
      'EVEN_OR_ODD: ' + EVEN_OR_ODD + '\n' +
      'NEW_LST:     ' , NEW_LST     ,
      'SNP_LST:     ' , SNP_LST1
    )
  }
}