import { Box, Button, Stack } from "@mui/material";
import { Icon } from "components/Icons";
import { useOpenHouseData } from "components/OpenHouseBanner/OpenHouseBanner.Data";
import React from "react";
import { useTranslation } from 'react-i18next'

function OpenHouse() {
  
  const { ready, MM, MMM, Title, Time, Description, Address } = useOpenHouseData();
  const { i18n:{language} } = useTranslation()

  return (
    <>
      {ready && (
        <Box sx={{ px: 2.5, my: { xs: 2, md: 3 } }}>
          <Stack
            direction="row"
            sx={{
              py: 1,
              border: "1px solid",
              borderColor: "common.black",
              lineHeight: 1.3,
            }}
          >
            {/* left */}
            <Box
              sx={{
                py: 2,
                px: 1.25,
                borderRight: "1px solid",
                borderRightColor: "common.black",
                textAlign: "center",
                lineHeight: 1,
              }}
            >
              <Box
                sx={{
                  fontSize: 35,
                  fontWeight: "bold",
                  mb: "2px",
                  width: "60px"
                }}
              >
                {MM}
              </Box>
              <Box
                sx={{
                  fontSize: 16,
                }}
              >
                {MMM}
              </Box>
            </Box>
            {/* right */}
            <Box px={1.5} pt={0.75}>
              <strong>{Title}</strong>
              <div>{Time}</div>
              <div dangerouslySetInnerHTML={{ __html:Address}}></div>
              <Button href={`https://soetorvet.dk/personlig-fremvisning`} sx={{ px: 0, py: 0.5, }} endIcon={<Icon icon="long-arrow" />} > Book personlig fremvisning </Button>
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default OpenHouse;
