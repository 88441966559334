import { useNavigate }              from 'react-router-dom'
import { options }                  from './Dropdown2.Data'
import { Portal }                   from 'components/Portal/Portal'
import { Backdrop }                 from 'styles/styles.backdrop'
import { AnimatePresence }          from 'framer-motion'
import { useBreak }                 from 'hooks/useBreak'
import { useTranslation }           from 'react-i18next'
import { Main, Option, Cell, Text } from './Dropdown2.Foldout.style'

export const Foldout = ({ state, setState, location }) => {
    
  const isDesktop = useBreak('md_up')
  const navigate  = useNavigate()
  const face      = options.findIndex( i => location == i.path )
  
  const { i18n:{language} } = useTranslation()

  const animation = {
    initial   : { transform: `translate(${isDesktop?'-50%':'-50%'},-500px)`},
    animate   : { transform: `translate(${isDesktop?'-50%':'-50%'},  59px)`},
    exit      : { transform: `translate(${isDesktop?'-50%':'-50%'},-500px)`},
    transition: { duration:0.2, ease:'linear' }
  }

  return (
    <>
      <Portal id={'Dropdown2Foldout'}>
        <AnimatePresence>
          { 
            state && ( 
              <>
                <Backdrop onClick={()=>setState(false)}/>
                <Main clr={clr} {...animation}>
                {
                  options.map(({ path, presentation }, key ) => {
                    const active = key == face ? true : false
                    const click = () => {
                      navigate('/' + language + path)
                      setTimeout(()=>{setState(false)},150)
                    } 
                    return (
                      <Option key={ key } active={ active }>
                        <Cell onClick={ click }>
                          <Text clr={ clr }>{ presentation }</Text>
                        </Cell>
                      </Option>
                    )
                  })
                }
                </Main>
              </>
            )
          }
        </AnimatePresence>
      </Portal>
    </>
  )
}