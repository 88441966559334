import { Box, Button } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import { _project } from 'state/store.projects'
import { getProjectData } from 'state/jotai.projectDataHooks'

function Downloads() {
  const downloads = getProjectData('downloads',0,[])

  return (
    <Box
      sx={{
        mt: "45px",
      }}
    >
      <Box sx={{ fontWeight: 700, mb: "18px" }}>Hent materiale</Box>
      {downloads.map((item, i) => {
        return (
          <Box key={i}>
            <Button
              variant="text"
              href={item?.Url}
              target="_blank"
              startIcon={<Icon icon="download" />}
            >
              <span>{item?.Text}</span>
            </Button>
          </Box>
        );
      })}
    </Box>
  );
}

export default Downloads;
