import { get, uget, rent } from 'components/Favorites/Favorites.Utilities'

const j = `${'\u00A0'} • ${'\u00A0'}`

export const data = {
  A : [
    {key:'1',joiner:j,properties:['Projektnr']},
    // {key:'1',joiner:j,properties:['Boligtype']},
    {key:'2',joiner:j,properties:['SalesTypeName']},
    {key:'3',joiner:j,properties:['Adresse']},
    {key:'4',joiner:j,properties:['Pris']},
    {key:'5',joiner:j,properties:['Areal','Vaer','Uderum']},
  ],
  B : [
    {key:'1',joiner:j,properties:['SalesTypeName']},
    {key:'2',joiner:j,properties:['Adresse']},
    {key:'3',joiner:j,properties:['Pris']} ,
    {key:'4',joiner:j,properties:['Areal','Vaer','Uderum','Aspects']},
  ],
  C : [
    {key:'1',joiner:j,properties:['Projektnr']},
    {key:'2',joiner:j,properties:['SalesTypeName']},
    {key:'3',joiner:j,properties:['Adresse']},
    {key:'4',joiner:j,properties:['Areal','Vaer','Uderum','Aspects']},
    {key:'5',joiner:j,properties:['Pris']},
  ]
 }

export const form = (unit,line) => line.properties
  .filter(m => {
    if ( m == 'Projektnr') return get(unit,'Projektnr')           ? true : true
    if ( m == 'Areal'    ) return get(unit,'Areal')               ? true : false
    if ( m == 'Vaer'     ) return get(unit,'VaerelserAntal')      ? true : false
    if ( m == 'Uderum'   ) return get(unit,'Uderum')              ? true : false
    if ( m == 'Aspects'  ) return get(unit,'FremhaevedeAspekter') ? true : false
    if ( m == 'Boligtype') return get(unit,'Boligtype')           ? true : false
    return true
  })
  .map(m => {
    if ( m == 'Projektnr'     ) return Projektnr (unit,m)
    if ( m == 'Boligtype'     ) return boligtype (unit,m)
    if ( m == 'Pris'          ) return pris      (unit,m)
    if ( m == 'SalesTypeName' ) return saletype  (unit,m)
    if ( m == 'Adresse'       ) return adresse   (unit,m)
    if ( m == 'Areal'         ) return fremhaev  (unit,m)
    if ( m == 'Vaer'          ) return fremhaev  (unit,m)
    if ( m == 'Uderum'        ) return fremhaev  (unit,m)
    if ( m == 'Aspects'       ) return fremhaev  (unit,m)
    return 'err'
  })
  .join( line.joiner )

const Projektnr = (i,x) => {
  return `Bolignr. ${ get(i,x) }`
}

const boligtype = (i,x) => {
  return `Boligtype: ${ get(i,x) }`
}

const prisLabel = (i) => {
  return i.Data['LejelejlighedsText'].Value
}

const pris     = (i) => {
  const a = '/md. i husleje'
  const b = ' kr.'
  const availableUnits = i?.Data?.UnitStatusID?.Value === 10;
  const availablePris = i?.Data?.Pris_Tekst_DK?.Value === "";
  const c = rent(i) ? 'Husleje' : 'Kontantpris'
  const d = rent(i) ? 'Pris' : ""
  const f = typeof uget(i,c) === 'string'
  const e = get(i,c) + (f ? '' : ( rent(i) ? b + a : b) )
  // const e = get(i,c) + (f ? '' : b + "/" + prisLabel(i) )

  const g =  availablePris ? e : "";
  return g
}

const saletype = (i,x) => get(i,x)

const adresse  = (i,x) => get(i,x)

const fremhaev = (i,x) => {

  if ( x == 'Areal'   ) return `${ get(i,x) } m²`
  if ( x == 'Vaer'    ) return `${ get(i,'VaerelserAntal') } vær.`
  if ( x == 'Uderum'  ) return `${ get(i,x) }`
  if ( x == 'Aspects' ) return `${ get(i,'FremhaevedeAspekter').split(',').join(j) }`
  
}