import { Box, Grid } from "@mui/material";
import UnitCard from "components/Unit/UnitCard";
import useUnitDrawer from "components/Unit/useUnitDrawer";
import { useAtom } from "jotai";
import React from "react";
import { fav_grid } from "state/store.global";
import { useFavoriteUnits } from "./useFavoriteUnits";

const Favorites = () => {
  const { units: favUnits } = useFavoriteUnits();
  const [isGrid] = useAtom(fav_grid);
  const { open } = useUnitDrawer();

  const handleClickCard = (unit) => {
    open(unit);
  };

  return (
    <Box sx={{ position: "relative", pt: 3 }}>
      <Box sx={{ px: 3, mb: 3 }}>
        Du har valgt{" "}
        <strong>
          {favUnits.length} {favUnits.length > 1 ? "boliger" : "bolig"}
        </strong>{" "}
        til favoritter
      </Box>

      <Box sx={{ px: 2 }}>
        <Grid container spacing={2}>
          {favUnits.map((unit) => {
            return (
              <Grid
                item
                key={unit.getId()}
                xs={12}
                sm={isGrid ? 6 : 12}
                md={isGrid ? 4 : 12}
              >
                <UnitCard
                  unit={unit}
                  layout={isGrid ? "grid" : "list"}
                  onClick={handleClickCard}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

Favorites.propTypes = {};

export default Favorites;
