import { useState, useEffect }      from 'react'
import { _project }                 from 'state/store.projects'
import { get }                      from 'state/jotai'
import { Legend, Dot, Status, Txt } from './ColorLegend.style'
import { motion }                   from 'framer-motion'
import { useBreak }                 from 'hooks/useBreak'

export const ColorLegend = () => {

  const [comb, setComb] = useState()

  const raw = get(_project, 'unitsraw', 'Units') 

  useEffect(()=>{
    if ( raw.length ) {
      setComb([...new Set( raw.map(i => {
        return `${i.Data.UnitStatusName.Value}___${i.Data.UnitColor.Value.toLowerCase()}`
      }))])
    }
  },[raw])

  const isDesktop = useBreak('md_up')

  const animation = {
    initial   : { transform: `translate(${isDesktop?'-50%':'-50%'},300px)`},
    animate   : { transform: `translate(${isDesktop?'-50%':'-50%'},0px)`},
    exit      : { transform: `translate(${isDesktop?'-50%':'-50%'},300px)`},
    transition: { duration:0.2, ease:'linear' }
  }


  if (raw.length && comb) return (
    <>
      <Legend {...animation}>
        {
          comb.map(i => {
            const name = i.split('___')[0]
            const color = i.split('___')[1]
            return (
              <Status key={i}>
                <Dot style={{background:color}}/>
                <Txt children={name}/>
              </Status>
            )
          })
        }
      </Legend>
    </>
  )

}