import { useEffect }        from 'react'
import { useLocation }      from 'react-router-dom'
import { active_project }   from 'state/store.global'
import { _bootstrap }       from 'state/store.bootstrap'
import { _project }         from 'state/store.projects'
import { getFocus, useSet } from 'state/jotai'

export const useSetActiveProject = () => {

  const type     = getFocus(_bootstrap, 'type')
  const location = useLocation().pathname
  const names    = getFocus(_bootstrap, 'projectnames')
  const ids      = getFocus(_bootstrap, 'projectids')?.join(',')

  const setActiveID  = useSet(active_project)

  useEffect(()=>{
    if ( location ) {
      const loc = type == 'poly' ? location.split('/')[1]   : null
      const fnc = type == 'poly' ? (x) => x.URLSafe === loc : null
      const obj = type == 'poly' ? names.filter( fnc )?.[0] : null
      const pid = type == 'poly' ? obj?.ProjectID || 0      : ids
      const pin = type == 'poly' ? names.indexOf(obj)       : 0
      setActiveID({ pid, pin }) 
    } 
  },[location])

  return 

}