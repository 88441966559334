import { Boolean }        from './Filter.Controls.Boolean'
import { Interval }       from './Filter.Controls.Interval'
import { MultiSelect }    from './Filter.Controls.MultiSelect'
import { Dropdown }       from './Filter.Controls.Dropdown'
import { Col, Row }       from './Filter.Controls.style'
import { Icon }           from 'assets/Icons_'
import { _project }       from 'state/store.projects'
import { getProjectData } from 'state/jotai.projectDataHooks'

const lookup = {
  'Kontantpris'   : 'price',
  'Husleje'       : 'price',
  'Månadshyra'    : 'price',
  'Størrelse'     : 'area',
  'Storlek'       : 'area',
  'Etage'         : 'floor',
  'Våning'        : 'floor',
  'Status'        : 'status',
  'Altantype'     : 'balcony',
  'Antal værelser': 'rooms',
  'Værelser'      : 'rooms',
  'Orientering'   : 'orientation',
  'Boligtype'     : 'boligtype'
}

const dropdownData = {
  Type:'Dropdown',
  Label:'Boligtype',
  Default:[0],
  
}

export const Controls = ({active, options, setFilters}) => {

  const PossibleValues = getProjectData('unitcategories')
                         .map( (i,j) => ({ID:j,Value:i,ValueProperty:'Boligtype'}))

  dropdownData.PossibleValues = PossibleValues

  clog( dropdownData )

  if ( !options.filter(i => i.Type == 'Dropdown' ).length > 0 ) {
    options.push(dropdownData)
  }

  return (
    <>
    {
        options.map( ( { Type, Label, ...i }, j ) => {
              
          const props = { ...i, active, setFilters, key:j, index:j, Label }
          const icon  = { icon:lookup[Label], color:clr?.icons, size:40 }

          return (
            <Col key={j}>
              <div style={{border:'0px solid black',display:'flex',flexDirection:'column',alignItems:'center',height:65}}>
                <Icon {...icon}/>
                <div style={{flex:'0 0 5px'}}/>
                <div style={{position:'relative',top:-7,color:'#fff'}}>{ Label }</div>
              </div>
              <Row>      
                { Type === 'MultiSelect' && <MultiSelect {...props} /> }
                { Type === 'MultiRange'  && <Interval    {...props} /> }
                { Type === 'Boolean'     && <Boolean     {...props} /> }
                { Type === 'Dropdown'    && <Dropdown    {...props} /> }
              </Row>
            </Col>
          )
        })
      }  
    </>
  )
  
}