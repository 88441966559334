import { useState } from 'react'
import { Icon }            from 'assets/icons_'
import styled              from 'styled-components/macro'
import { BREAK_ }          from 'constants'
import { Portal }          from 'components/Portal/Portal'
import { Backdrop }        from 'styles/styles.backdrop'
import { AnimatePresence } from 'framer-motion'
import { useBreak }        from 'hooks/useBreak'
import { motion }          from 'framer-motion'
import { ColorLegend }     from 'components/ColorLegend/ColorLegend'
import { ColorLegendPopup } from 'components/ColorLegend/ColorLegendPopup'

export const Status = ({}) => {

  const isMobile = useBreak('md_dn')

  const [state, setState] = useState(false)
  const [state2, setState2] = useState(false)

  const animation = {
    initial    : { opacity:0 },
    animate    : { opacity:1 },
    exit       : { opacity:0 },
    transition : { duration:0.5, ease:'linear' },
  }

  const handlerMobile = () => {
    if (isMobile) 
      setState(!state) 
    else 
      return
  }
  const handlerDesktop = (a) => {
    if (isMobile)
      return 
    else
      setState2(a)
  }


  return (
    <>
      <Wrap as={motion.div} {...animation} 
        onClick={handlerMobile}
        onMouseEnter={()=>handlerDesktop(true)}
        onMouseLeave={()=>handlerDesktop(false)}
      >
        <Icon icon='info' size={ isMobile ? 20 : 25 } color='#000'/>
        Status
      </Wrap>
      <Portal id={'StatusColorLegend'}>
        <AnimatePresence>
          {
            state && (
              <>
                <Backdrop onClick={()=>setState(false)}/>
                <ColorLegend/>
              </>
            )
          }
          {
            state2 && (
              <>
                {/* <Backdrop onClick={()=>setState(false)}/> */}
                <ColorLegendPopup/>
              </>
            )
          }
        </AnimatePresence>
    </Portal>
    </>
  )

}

const Wrap = styled.div`
  position: absolute;
  top: calc(100% - 205px);
  left: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
  width: fit-content;
  cursor: pointer;
  line-height: 1;
  ${BREAK_.md_dn} {
    font-size: 17px;
    left: 20px;
    top: calc(100% - 175px);
  }
`