import { Button, IconButton } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useAtom } from "state/jotai";
import { share_content, vis_share } from "state/store.global";

function ButtonShare({ unitId, iconOnly }) {
  const setShare = useAtom(vis_share)[1];
  const shareContent = useAtom(share_content)[1];

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      const content = unitId;
      setShare(true);
      shareContent(content);
    },
    [unitId]
  );

  return (
    <>
      {iconOnly ? (
        <IconButton onClick={handleClick}>
          <Icon icon="share" />
        </IconButton>
      ) : (
        <Button
          type="button"
          onClick={handleClick}
          color="inherit"
          startIcon={<Icon icon="share" />}
        >
          <span>Del</span>
        </Button>
      )}
    </>
  );
}

ButtonShare.propTypes = {
  unitId: PropTypes.number.isRequired,
  iconOnly: PropTypes.bool,
};

export default ButtonShare;
