import { Box, Divider, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import ButtonCompare from "./ButtonCompare";
import ButtonFavorite from "./ButtonFavorite";
import Unit from "./Unit.model";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll";
import useUnitPage from "./useUnitPage";

const UnitCard = ({ unit, layout, onClick }) => {
  const id = unit.getId();
  const status = unit.getStatus();
  const floorplan = unit.getFloorplanUrl(true);
  const projeknr = `Bolignr. ${unit.getValue("Projeknr")}`;
  const type = unit.getValue("SalesTypeName");
  const address = unit.getAddress();
  const price = unit.getPrice();
  const pricePf = unit.isRent() ? "Husleje" : "Kontantpris";
  const area = `${unit.getValue("Areal")} ${unit.getPostfix("Areal")}`;
  const rooms = `${unit.getValue("VaerelserAntal")} værelser`;
  const uderum = `${unit.getValue("Uderum")}`;
  const priceText = unit.getPriceText();
  const navigateTo = useNavigate();
  const [refContainer] = useUnitPage();

  const { i18n: { language } } = useTranslation();
  
  const handleClick = () => {
    navigateTo(`/${language}/unit/${id}`);

    animateScroll.scrollToTop({
      container: refContainer,
      duration:500
    });
    log({ 
      action:'clicked_apartment_on_listview',
      unitId:id
    })
  };

  return (
    <Box
      sx={{
        backgroundColor: `${status.color}22`,
        borderTop: "grid" === layout ? `6px solid ${status.color}` : "none",
        borderLeft: "list" === layout ? `6px solid ${status.color}` : "none",
        borderRadius: "6px",
        display: "flex",
        alignItems: 'center',
        flexDirection: "grid" === layout ? "column" : "row",
        "&:hover":{
          backgroundColor: `${status.color}60`,
          cursor: "pointer"
        }
      }}
      onClick={handleClick}
    >
      {/* image */}
      <Box
        sx={{
          display: {
            md: "block",
            xs: "list" === layout ? "none" : "block",
          },
          width: "grid" === layout ? "100%" : "auto",
          maxWidth: "grid" === layout ? "100%" : "50%",
          minWidth: 280,
          padding:0.5,
        }}
      >
        <Box
          component="img"
          src={floorplan}
          sx={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          alt="floorplan"
        />
      </Box>

      <Box
        sx={{
          position: "relative",
          lineHeight: {
            md: 2,
            xs: 1.5,
          },
          p: "10px 18px",
          width: "grid" === layout ? "auto" : "100%",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: 18,
            top: 18,
            fontWeight: 700,
            color: status.color,
          }}
        >
          {status.label}
        </Box>

        <Box sx={{ fontWeight: 700 }} >{projeknr}</Box>
        <div>{type}</div>
        <div>{address}</div>
        <div>
          {/* {price} {pricePf} */}
          { priceText ? '' : price }
        </div>
        <div>
          {area} &nbsp;•&nbsp; {rooms} &nbsp;•&nbsp; {uderum}
        </div>

        <Divider sx={{ mt: 1, mb: 0 }} />
        {/* actions */}
        <Stack direction="row" justifyContent="space-between">
          <ButtonCompare unitId={id} />
          <ButtonFavorite unitId={id} />
        </Stack>
      </Box>
    </Box>
  );
};

UnitCard.propTypes = {
  unit: PropTypes.instanceOf(Unit),
  layout: PropTypes.oneOf(["list", "grid"]),
  onClick: PropTypes.func,
};

UnitCard.defaultProps = {
  layout: "grid",
  onClick: () => {},
};

export default UnitCard;
