import styled, {css} from 'styled-components'

export const Track = (props, state) => (
  <StyledTrack {...props} index={state.index}></StyledTrack>
)

const StyledTrack = styled.div(
  ({ index }) => css`
    ${
      '' /* height:  ${ ( index === 2 ) ? '1px' : ( index === 1 ) ? '3px' : '1px' }; */
    }
    height: 1px;
    position: absolute;
    background: ${index === 2
      ? window.clr?.buttons
      : index === 1
      ? window.clr?.filter_range_controls
      : window.clr?.filter_range_controls };
  `
)