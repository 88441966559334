const Months = [
  'Januar',
  'Februar',
  'Marts',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'December',
]

const Days = [
  'Søndag',
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag'
]

export const WDay = (x) => {
  const date = new Date(x)
  return Days[date.getDay()]
}

export const MDay = (x) => {
  const date = new Date(x)
  return date.getDate()
}

export const Month = (x) => {
  const date = new Date(x)
  return Months[date.getMonth()]
}
