import styled, { css } from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position:fixed;
  z-index: 1;
  top:0;
  left:0;
  height:calc(100% - 60px);
  width:100%;
  border-top: ${({ clr }) => `1px solid ${clr.icons}40`};
  background-color: ${({ clr }) => clr.primary};
  overflow: scroll;
  font-size: 0.875rem;
  ${sb.hide};
`

export const Tile = styled.div`
  display: flex;
  color: ${({ clr }) => '#fff'};
  font-weight: bold;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 10px 20px;
`
export const Split = styled.div`
  height: 1px;
  background-color: ${({ clr }) => `${clr.icons}40`};
  width: 100%;
`
