import { Box } from "@mui/material";
import Downloads from "components/Downloads/Downloads";
import React from "react";

export const ViewDownloads = () => {
  return (
    <Box
      sx={{
        pt: { lg: "60px", xs: "120px" },
        pb: "60px",
      }}
    >
      <Downloads />
    </Box>
  );
};
