import { useState }                from 'react'
import { useAtom }                 from 'state/jotai'
import { share_content }           from 'state/store.global'
import { vis_share }               from 'state/store.global'
import { Backdrop }                from 'styles/styles.backdrop'
import { STATICS }                 from 'api/api'
import { Icon }                    from 'assets/Icons_'
import { Button, Stack }           from '@mui/material'
import { Container, Inner, Input } from './Share.Portal.style'
import { useTranslation }          from 'react-i18next'
import { isMobile }                from 'react-device-detect'

import { FacebookShareButton, FacebookMessengerShareButton } from 'react-share'

const data = {
  Header1 : 'Del bolig',
  Text1   : 'Forestiller du dig at denne bolig er det perfekte match til en person i dit netværk? Del den med det samme.',
  Header2 : 'Indtast email',
}

export const SharePortal = () => {

  const content             = useAtom(share_content)
  const setShare            = useAtom(vis_share)[1]
  const { i18n:{language} } = useTranslation()
  const [email, setEmail]   = useState()
  
  const close   = { icon: 'close6', color: '#fff', wrap: { position: 'absolute', top: 20, right: 20 }, size: '20', click: () => setShare(false), }
  const homeUrl = window.location.origin;
  const unitUrl = `${homeUrl}/${language}/unit/${content[0]}`;

  const inputChange = e => { setEmail(e.target.value) }
  const handleDelBolig = () => {
    const subject = 'Bolig'
    const message = unitUrl
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
    setShare(false)
  }
  const handleCopyToClipboard = () => { 
    navigator.clipboard.writeText(unitUrl) 
    setShare(false)
  }
  const handleSMS = () => {
    const message = unitUrl
    window.location.href = `sms:?&body=${message}`
  }


  return (
    <>
      <Backdrop onClick={() => { setShare(false) }} />
      <Container>
        <img src={`${STATICS}generic.png`} alt='' />
        <Icon {...close} />
        <Inner>
          <h2>{data.Header1}</h2>
          <p>{data.Text1}</p>
          <div style={{ height: 20 }} />
          <h3>{data.Header2}</h3>
          <Input type='text' placeholder='email@email.dk' onChange={inputChange}></Input>
          <div style={{ height: 20 }} />
          <Stack direction='row' spacing='1px' justifyContent='flex-start'>
            <Button variant='contained' size='large' onClick={handleDelBolig}>Del bolig</Button>
          </Stack>
          <div style={{height:40}}/>
          <div style={{display:'flex',gap:20}}>
            <FacebookShareButton url={unitUrl}><Icon icon='facebook' color='#000' size='30'/></FacebookShareButton>
            <FacebookMessengerShareButton url={unitUrl}><Icon icon='messenger' color='#000' size='30'/></FacebookMessengerShareButton>
            <Icon icon='link' color='#000' size='30' click={handleCopyToClipboard}/>
            {isMobile && <Icon icon='sms' color='#000' size='30' click={handleSMS}/> }
          </div>
          <div style={{height:40}}/>
        </Inner>
      </Container>
    </>
  )

}

