import { Box, Button } from "@mui/material";
import React from "react";
import { animateScroll } from "react-scroll";
import useUnitPage from "./useUnitPage";

function CTA() {
  const [refContainer] = useUnitPage();

  const handleClick = () => {
    const targetElement = document.getElementById("unit_page_contact_form");

    if (targetElement) {
      const offset = targetElement.offsetTop;
      animateScroll.scrollTo(offset, {
        container: refContainer,
      });
    }
  };

  return (
    <Box textAlign="center">
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
      >
        Kontakt
      </Button>
    </Box>
  );
}

CTA.propTypes = {};

export default CTA;
