import { Box, Button, IconButton } from "@mui/material";
import { IconBadge } from "components/Icons";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useAtom } from "state/jotai";
import { compares } from "state/store.global";

function ButtonCompare({ unitId, iconOnly }) {
  const [comps, setComps] = useAtom(compares);
  const [count, setCount] = useState("");

  useEffect(() => {
    if (comps.includes(unitId)) {
      setCount(comps.indexOf(unitId) + 1);
    } else {
      setCount("");
    }
  }, [unitId, comps]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (comps.includes(unitId)) {
        // remove from list.
        const list = comps.filter((i) => i !== unitId);
        setComps(list);
      } else {
        // add to list.
        const list = [...comps];
        if (3 === comps.length) {
          // max 3 items, remove an item.
          list.pop();
        }
        list.push(unitId);
        setComps(list);
      }
    },
    [comps, unitId]
  );

  return (
    <>
      {iconOnly ? (
        <IconButton onClick={handleClick}>
          <IconBadge icon="compare" badge={count} />
        </IconButton>
      ) : (
        <Button
          type="button"
          onClick={handleClick}
          color="inherit"
          startIcon={<IconBadge icon="compare" badge={count} />}
        >
          <Box component="span" ml="4px">
            {comps.includes(unitId) ? "Tilføjet" : "Sammenlign"}
          </Box>
        </Button>
      )}
    </>
  );
}

ButtonCompare.propTypes = {
  unitId: PropTypes.number.isRequired,
  iconOnly: PropTypes.bool,
};

export default ButtonCompare;
