import styled from 'styled-components/macro'
import { MENU } from 'constants'

export const Menu = styled.div`
  position:fixed;
  /* z-index:9999; */
  top:0;
  left:0;
  height: ${MENU.MOBILE_NAV_H}px;
  width:100%;
  background-color: ${ ({clr}) => clr.primary };
  color: ${ ({clr}) => clr.primary_text };
  display:flex;
  > span:nth-child(1) {
    /* border:1px solid blue; */
    display:flex;
    align-items:center;
    justify-content:flex-start;
    align-items:flex-end;
    /* border:1px solid black; */
    max-width:120px;
    min-width:120px;
  };
  > span:nth-of-type(2) {
    /* border:1px solid green; */
    /* flex:1; */
    display:flex;
    justify-content:flex-end;
  }

`

export const Gap = styled.div`
  width:${({i}) => i}px;

`