class Unit {
  data = {};

  constructor(data) {
    this.data = data;
  }

  getId = () => {
    return this.data?.UnitID ?? "";
  };

  getValue(property = "", def = "") {
    return this.data?.Data?.[property]?.Value ?? def;
  }

  getUnformattedValue(property = "", def = "") {
    return this.data?.Data?.[property]?.UnformattedValue ?? def;
  }

  getPostfix(property = "", def = "") {
    return this.data?.Data?.[property]?.Postfix ?? def;
  }

  getStatus() {
    return {
      id: this.getValue("UnitStatusID"),
      label: this.getValue("UnitStatusName"),
      color: this.getValue("UnitColor"),
    };
  }

  getLejlighedsnr() {
    return this.data?.Data?.['Lejlighedsnr']?.Value;
  }

  getAddress() {
    return this.getValue("Adresse");
  }

  getImages(floorplan = true) {
    const gallery = this.data?.Gallery?.ListOfPhotos ?? [];
    if (!floorplan) {
      return gallery.map((i) => ({ url: i?.Url })).slice(1);
    }
    return gallery.map((i) => ({ url: i?.Url }));
  }

  isRent() {
    return this.getValue('SalesTypeID') === 103;
  }

  getPrice() {
    const price = this.isRent() ? this.getValue("Husleje") : this.getValue("Kontantpris");
    
    const hasPostfix = this.getValue("Price_text") ? false : true ;
    const postfix = this.getPostfix("Kontantpris");
    return `${price} ${hasPostfix ? postfix : ''}`;
  }
  getPris(){
    const pris = this.getValue("Pris") + " " + this.getPostfix("Pris")
    return `${pris}`
  }
  getDescriptionTitle() {
    return this.getValue("UnitDescriptionHeadline");
  }

  getDescription() {
    return this.getValue("UnitDescription");
  }

  getAreaDescriptionTitle() {
    return this.getValue("AreaDescriptionHeadline");
  }

  getAreaDescription() {
    return this.getValue("AreaDescription");
  }

  getProperties(isComparable = false) {
    if (!this.data?.Groups?.length || !this.data?.Data) { return []; }

    const unitGroups = this.data.Groups;
    const unitData = this.data.Data;

    const groupsObject = Object.keys(unitData).reduce((g, key) => {
      
      const data = unitData[key];
      
      const groupName = data.GroupName;
      
      if ( !groupName || false === data?.ShowValue || !unitGroups.includes(groupName) ) { return g; }

      if (isComparable && !data?.Comparable) { return g; } if (!g[groupName]) { g[groupName] = []; }
      
      g[groupName].push(data);
      
      return g;

    }, {});

    const groups = unitGroups
      .map((groupName) => {
        return {
          title: groupName,
          data: groupsObject[groupName],
          order: groupsObject[groupName][0]
            ? groupsObject[groupName][0].GroupOrder
            : 0,
        };
      })
      .sort((a, b) => {
        return a.order - b.order;
      });

    return groups;
  }

  getStatistics() {
    const {
      Bookmarked = "",
      BookmarkedLabel = "",
      Seen = "",
      SeenLabel = "",
      LastSeen = "",
      LastSeenLabel = "",
    } = this.data?.Statistics ?? [];

    return {
      bookmarked: {
        label: BookmarkedLabel,
        value: Bookmarked,
      },
      seen: {
        label: SeenLabel,
        value: Seen,
      },
      lastSeen: {
        label: LastSeenLabel,
        value: LastSeen,
      },
    };
  }

  getFloorplanUrl(thumbs = false) {
    return thumbs
      ? this.data?.Gallery?.Floorplan?.UrlThumbnail
      : this.data?.Gallery?.Floorplan?.Url ?? "";
  }

  getUrl(base = "") {
    const id = this.getId();
    return `${base}/unit/${id}`;
  }

  getPriceText() {
    return this.data?.Data?.['Price_text']?.Value
  }

}

export default Unit;
