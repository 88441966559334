import { Box, Grid, styled } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";

function getDataTime(v) {
  const KL = v?.split(" ")[1].slice(0, -3) ?? "";
  const DD = v?.split(" ")[0].split("-")[2] ?? "";
  const MM = v?.split(" ")[0].split("-")[1] ?? "";
  const YY = v?.split(" ")[0].split("-")[0].slice(2, 4) ?? "";
  const HHMM = `${KL}`;
  const DDMMYY = DD && MM && YY ? `${DD}.${MM}.${YY}` : "";
  return [HHMM, DDMMYY];
}

function Statistics({ unit }) {
  const { bookmarked, seen, lastSeen } = unit.getStatistics();

  const [time, date] = getDataTime(lastSeen.value);
  const unitId = unit.getId();
  const type = unit.getValue("Boligtype");
  const unitNo = unit.getLejlighedsnr();

  return (
    <Box
      sx={{
        py: { xs: 8 },
        px: { xs: 3, md: 5 },
        backgroundColor: "secondary.main",
        color: "secondary.contastText",
      }}
    >
      <Box
        sx={{
          fontSize: 20,
          fontWeight: 700,
          mb: { xs: 3, md: 4 },
        }}
      >
        Om bolig {unitNo}
      </Box>
      <Box
        sx={{
          maxWidth: 500,
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <StyledItem>
              <Icon icon="heart" />
              <div>{bookmarked.label}</div>
              <div>{bookmarked.value}</div>
            </StyledItem>
          </Grid>
          <Grid item xs={4}>
            <StyledItem>
              <Icon icon="eye" />
              <div>{seen.label}</div>
              <div>{seen.value}</div>
            </StyledItem>
          </Grid>
          <Grid item xs={4}>
            <StyledItem>
              <Icon icon="clock" />
              <div>
                {lastSeen.label} {date}
              </div>
              <div>{time}</div>
            </StyledItem>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Statistics.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

const StyledItem = styled((props) => <Box {...props} />)({
  padding: "0 10px",
  maxWidth: "100%",
  textAlign: "center",
  ".MuiSvgIcon-root": {
    fontSize: 40,
    marginBottom: "8px",
  },
  "> div:last-child": {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 1,
    marginTop: "8px",
  },
});

export default Statistics;
