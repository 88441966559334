import styled from 'styled-components/macro'

export const Main = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
  display: grid;
  gap: 10px;
  /* border:1px solid black; */
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  background:transparent;
`

export const Item = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 0px 1px 0 1px;
  /* border:1px solid black; */
  border-radius: 5px;
  border-top: 8px solid ${({ color }) => color};
  background-color: ${({ color }) => (() => color + '22')()};
  :hover { background-color: ${({ color }) => (() => color + '33')()}; }
`