import { useEffect }      from 'react'
import { Gallery }        from 'components/Gallery/Gallery'
import styled             from 'styled-components/macro'
import { scrolly }        from 'state/store.global'
import { useGet, useSet } from 'state/jotai'
import { usePrevious }    from 'hooks/usePrevious'
import { compares }       from 'state/store.global'
import { DualMenu } from 'components/DualMenu/DualMenu'

export const ViewGallery = () => {

  const setScroll = useSet(scrolly)
  const scroll    = useGet(scrolly)
  const comps     = useGet(compares)

  useEffect(()=>{ setScroll(0) },[])

  const prevScroll = usePrevious(scroll)

  const a = scroll?.toFixed(0) ?? 0
  const b = prevScroll?.toFixed(0) ?? 100

  return (
    <>
      <div style={{height:120}}/>
      <Container>
        <Gallery />
      </Container>
      {/* {a < b || a < 30 ? <DualMenu mode={'list'} /> : null} */}
    </>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
  width: 100%;
  height: 100%;
  /* padding-top: 110px; */
  padding-bottom:50px;
  /* border: 10px solid red; */
`
