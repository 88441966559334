import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRelatedUnits } from "./hooks";
import Unit from "./Unit.model";
import UnitCard from "./UnitCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function RelatedUnits({ unit }) {
  
  const relatedUnits = useRelatedUnits(unit.getId());
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigateTo = useNavigate()

  const { i18n: { language } } = useTranslation()

  const handleClick = () => navigateTo(`/${language}/list`)

  return (
    <Box
      sx={{
        px: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: 700, mb: 3, fontSize: "1.25rem" }}
      >
        Måske er du også interesseret i...
      </Typography>
      <>
        <Swiper slidesPerView={isMobile ? 1.2 : 2.5} spaceBetween={15}>
          {relatedUnits.map((unit, i) => (
            <SwiperSlide key={i}>
              {/* <RelatedUnitCard unit={unit} /> */}
              <UnitCard unit={unit} />
            </SwiperSlide>
          ))}
        </Swiper>
      </>

      <Box
        sx={{
          mt: 4,
          textAlign: "center",
        }}
      >
        <Button variant="contained" onClick={handleClick}>Se alle boliger</Button>
      </Box>
    </Box>
  );
}

RelatedUnits.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default RelatedUnits;
