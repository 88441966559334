import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import PropertiesRow from "./PropertiesRow";

const PropertiesGroup = ({ groupTitle, unitsProperties }) => {
  const rows = [];

  unitsProperties.forEach((unitProperties) => {
    unitProperties.forEach((prop) => {
      const id = prop.PropertyID;
      if (!rows[id]) {
        rows[id] = [prop];
      } else {
        rows[id].push(prop);
      }
    });
  });

  return (
    <Box>
      <Box>
        <Box sx={{ fontWeight: 700, mb: 3, px: 3, fontSize: 17, }} >
          {groupTitle}
        </Box>
      </Box>
      {rows.map((row, i) => (
        <Box key={i}>
          <PropertiesRow items={row} />
        </Box>
      ))}
    </Box>
  );
};

PropertiesGroup.propTypes = {
  groupTitle: PropTypes.string,
  unitsProperties: PropTypes.array.isRequired,
};

export default PropertiesGroup;
