import { useFetch } from 'hooks/fetch/useFetch'
import { NAVLINKS } from 'api/api'

export const useFetch_Navigation = () => {

  const { D:nav } = useFetch({url:NAVLINKS,file:'useFetchNav.jsx',mock:false})
  const res = nav?.length ? true : false

  return { nav, res }

}