import { css } from 'styled-components'
import { BREAK_ } from 'constants'

export const root = css`
  /* Create a root stacking context */

  #root {
    ${'' /* display: flex; */}
    ${'' /* flex-direction: row; */}
    ${'' /* ${ BREAK_.md_dn } { flex-direction: column }; */}
    ${'' /* isolation: isolate; */}
    ${'' /* width:100%; */}
    height:100%;
    ${'' /* border:10px solid blue; */}
  }
`