import styled from 'styled-components/macro'

export const Main = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  cursor: pointer;
  user-select: none;
`
export const Label = styled.div`
  white-space: nowrap;
  font-weight: 600;
  padding: 0 5px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  height: 59px;
`
export const Value = styled.div`
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  text-align: center;
`
