import { get, useAtom }   from 'state/jotai'
import { _isometry }      from 'state/store.isometry'
import { _subiso }        from 'state/store.subiso'
import { vis_filter }     from 'state/store.global'
import { toggle_drawer }  from 'state/actions'

import { Dropdown }       from 'components/Dropdown2/Dropdown2'
import { Mode360 }        from 'components/IsometryRotation/360'
import { Sort }           from 'components/Sort2/Sort'
import { Back }           from 'components/Isometry/Isometry.Back'

import { Icon }           from 'assets/Icons_'
import { useLocation }    from 'react-router-dom'
import { Nav, Filter }    from './TopMenu.style'
import { useParams }      from 'react-router-dom'
import LanguageMenu       from 'components/LanguageMenu/LanguageMenu'
import { useBreak }       from 'hooks/useBreak'
import NavItems           from 'components/Nav/NavItems'
import { iso_free }       from 'state/store.global'


// FAVORITES
import ToolbarFavorites from 'components/Favorites/Toolbar'

export const TopMenu = ({left,right,nav}) => {
  
  const rmode        = useAtom(iso_free)[0]
  const isDesktop    = useBreak('md_up')
  const sub          = useParams('sub').sub
  const frames1      = get(_isometry, 'frames')
  const frames2      = get(_subiso, 'frames')?.find(i => i.NAME == sub)?.RES
  const clickFilter  = () => toggleDrawer('filter')
  const isFilter     = useAtom(vis_filter)[0]
  const toggleDrawer = useAtom(toggle_drawer)[1]
  const filter       = { size:18, icon:'filters', color:isDesktop?'#fff':'#000' }
  const here         = useLocation().pathname
  
  const showAt       = ['isometry','list','favorites','unit','gallery']
                       .some(i => here.includes(i))
  const atModel      = here.includes('isometry')
  const atList       = here.includes('list')
  const atFavorites  = here.includes('favorites')
  const atUnit       = here.includes('unit')
  
  const doesRotate = ( sub ? frames2?.length : frames1?.length ) > 10

  if ( showAt ) return (
    <>
      <Nav clr={clr} left={left} right={right} nav={nav} layout>
        
        {/* FAVORITES ONLY */}
        {atFavorites ? (
          <>
            <ToolbarFavorites />
          </>
        ) : (
          <>
            <span>
              { !atUnit && (
                <Filter onClick={clickFilter}>
                  <Icon {...filter} />
                  Filter
                </Filter>
              )}
              {atModel && rmode && <Back />}
            </span>
            <span>
              {/* { atModel && <Dropdown /> } */}
              {atList && <div></div>}
            </span>
            <span>
              {atModel && <Mode360 />}
              {!isDesktop && atList && <Sort />}
              {isDesktop && <NavItems hideNav={false}/> }
            </span>
          </>
        )}
      </Nav>
    </>
  );
  return false

}