import styled from 'styled-components'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Legend = styled(motion.div)`
  /* border:10px solid red; */
  position: fixed;
  bottom: 230px;
  left: 100px; 
  ${ BREAK_.md_dn } {
    left:0;
  }
  width: 390px;
  height: 183px;
  background-color: #F3F3F3;
  padding: 30px 25px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
`
export const Dot = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  /* border:1px solid black; */
`
export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding:0 5px;
  /* border:1px solid black; */
`
export const Txt = styled.div`
  line-height:1;
  white-space:nowrap;
  font-size:16px;
  /* border:1px solid black; */
`

export const Group = styled.div`
  display: grid;
  padding-top: 20px;
  grid-template-columns: repeat(2, 1fr);
  /* border:5px solid green; */
  width: 100%;
  gap:10px;
  /* height: 1; */
`
export const Header = styled.div`
  /* border:5px solid black; */
  width: 100%;
  font-size: 17px;
  font-weight: 600;
`