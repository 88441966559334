import { _project }          from 'state/store.projects'
import { _bootstrap }        from 'state/store.bootstrap'
import { get, useGet }       from 'state/jotai'
import { WDay, MDay, Month } from './OpenHouseBanner.utilities'

export const useOpenHouseData = () => {

  const sitename  = useGet(_bootstrap).sitename
  const openhouse = get(_project, 'openhouse')

  const Y = sitename ?? 'SITENAME'
  const P = openhouse?.FromDate    || '12/27/2022' // Remember safari requires specific format of dates
  const X = openhouse?.Title       || 'Åbent hus'
  const W = openhouse?.FromTime    || ''
  const V = openhouse?.ToTime      || ''
  const I = openhouse?.Address     || 'Tilfældig addresse'
  const K = openhouse?.Description || 'Adresse 1, 1. tv.'

  const Q = `${MDay(P) ?? ''}`
  const R = `${Month(P) ?? ''}`
  const S = `${X} i ${Y}`
  const T = `${WDay(P)} kl ${W} - ${V}`
  const U = `${I}`
  const L = `${K}`


  return {
    ready      : W && V,
    MM         : Q,
    MMM        : R,
    Title      : S,
    Time       : T,
    Address    : U,
    Description: L,
  }


}