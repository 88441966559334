import {
  getSessionID,
  getDeviceID,
  getSessionExpiry,
  setSessionID,
  setDeviceID,
  setSessionExpiry,
  isSessionExpired,
  toString
} from 'logging/logging.functions.jsx'

import { nanoid }     from 'nanoid'
import { axios_live } from 'utilities/axios.instances'
import { LOG }        from 'api/api'

window.log = function({
  action  = '',
  unitId  = '',
  addData = {},
  logData = {}
}){
  
  const IP              = window.CIP
  const isSessionId     = getSessionID()
  const isDeviceId      = getDeviceID()
  const isSessionExpiry = getSessionExpiry()

  if ( !isDeviceId ) { 
    const newlyGeneratedDeviceID = nanoid()
    setDeviceID(newlyGeneratedDeviceID)
  }

  if ( !isSessionId ) { 
    const newlyGeneratedSessionID = nanoid()
    setSessionID(newlyGeneratedSessionID)
  }

  if ( !isSessionExpiry || isSessionExpired() ) {
    const newExpiry = new Date().getTime() + 2400000
    const newlyGeneratedSessionID = nanoid()
    setSessionExpiry(newExpiry)
    setSessionID(newlyGeneratedSessionID)
  }
  
  const data = {
    Action    : action,
    Data      : logData,
    DeviceID  : isDeviceId  ? isDeviceId  : getDeviceID(),
    SessionID : isSessionId ? isSessionId : getSessionID(),
    AddData   : { Referrer: document.referrer, IP, ...addData },
    UnitID    : unitId,
  }
  // console.log( LOG(1) )
  axios_live.post(LOG(1), data)
    // .then( res => console.log(toString(res.config.data)))
    .then( res => {})
    .catch( err => console.log(err) )
    // console.log( data )
    // console.log( JSON.stringify(data,0,0) )
}