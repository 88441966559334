import React from "react";
import Favorites from "components/Favorites/Favorites";
import { Box } from "@mui/material";

export const ViewFavorites = () => {
  return (
    <>
      <Box sx={{ pt: { xs: "120px", md: "59px" }, pb: "100px" }}>
        <Favorites />
      </Box>
    </>
  );
};
