import React from "react";
import { Box } from "@mui/material";
import Compare from "components/Compare/Compare";

export const ViewCompare = () => {
  return (
    <>
      <Box sx={{ pt: { xs: "59px", md: "0" }, pb: "100px" }}>
        <Compare />
      </Box>
    </>
  );
};
