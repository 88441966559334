import { motion }            from 'framer-motion'
import { AnimatePresence }   from 'framer-motion'
import { Icon }              from 'assets/Icons_'
import { PoweredBy }         from 'components/PoweredBy/PoweredBy'
import { Menu, Tile, Split } from './Nav.Mobile.Foldout.style'
import { useTranslation }    from 'react-i18next'
import { Link } from 'react-router-dom'

export const Foldout = ({nav,show}) => {
  
  const { i18n:{language} } = useTranslation()
  
  const menu = {
    initial   : { y:'-100%' },
    animate   : { y: 60 },
    exit      : { y:'-100%' },
    transition: { duration:0.3 },
  }

  return (
    <AnimatePresence>
     { show && <Menu 
          as={motion.div} 
          clr={clr} 
          {...menu}>
          <div style={{flex:1}}/>
          { 
            nav.map(({url,text,icon},j) => {
            
              const ico = { href:url[language], icon, size:30, color:clr.icons }

              return ( 
                <div key={text[language]}>
                  { j > 0 && <Split clr={clr}/> }
                  <Link to={url[language]} key={text[language]} style={{textDecoration:'none'}}>
                    <Tile clr={clr}>
                      <Icon {...ico}/>
                      { text[language] }
                    </Tile>
                  </Link>
                </div>
              )
            })
          }
          <div style={{flex:1}}/>
          <PoweredBy/>
        </Menu>
      }
    </AnimatePresence>
  )
}
