import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { vis_calculate_benefit } from 'state/store.global'
import { useAtom } from 'state/jotai'
import { useHousingBenefit } from 'components/HousingBenefit/HousingBenefit.hook'

function Properties({ groups, indent, priceText }) {

  const setBenefit  = useAtom(vis_calculate_benefit)[1]
  const output      = useHousingBenefit()

  return (
    <>
      {groups &&
        groups.map((group, i) => {
          return (
            <Box key={i} sx={{ mb: 5, "&:last-child": { mb: "0", }, }} >
              <Box sx={{ fontWeight: 700, mb: "10px" }}>{group.title}</Box>
              <div>
                {group.data &&
                  group.data.map((data, i) => {
                    
                    const label = data.Label;
                    const value = data.Value === true ? "Ja" : data.Value === false ? "Nej" : data.Value;
                    const postfix = ( priceText && 100 === data.PropertyID ? "" : data.Postfix ) ?? "";
                    
                    {/* if ( data.PropertyID !== 113 ) return ( */}
                    return (
                      <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', px: indent ? 3 : 0, py: 1.5, borderBottom: '1px solid rgba(112, 112, 112, 0.1)', '&:last-child': { borderBottom: 'none', }, }} >
                        <div>{label}</div>
                        {data.PropertyID !== 113 ? ( <div> {value} {postfix} </div> ) : ( <>
                          { 
                            output 
                              ? <div onClick={() => setBenefit(true)} style={{color:'unset',cursor:'pointer',textDecoration:'underline'}}>{output}</div> 
                              : <div onClick={() => setBenefit(true)} style={{color:'unset',cursor:'pointer',textDecoration:'underline'}}>Beregn</div>
                          }
                          </>
                        )}
                      </Box>
                    )
                  })}
              </div>
            </Box>
          );
        })}
    </>
  );
}

Properties.propTypes = {
  groups: PropTypes.array.isRequired,
  indent: PropTypes.bool,
};

export default Properties;
