import { AppBar, Button, IconButton, Stack, Toolbar as ToolbarMUI } from "@mui/material";
import { Icon } from "components/Icons";
import Dropdown from "components/mui/Dropdown";
import DropdownItem from "components/mui/DropdownItem";
import { useAtom } from "jotai";
import React, { useRef } from "react";
import { toggle_drawer } from "state/actions";
import { fav_grid } from "state/store.global";
import { getProjectData, setProjectData } from 'state/jotai.projectDataHooks'
import { applysorting } from 'utilities/utility.sort'

const Toolbar = () => {
  return (
    <AppBar position="static" elevation={0} sx={{ top: 150, bgcolor: "#F9F3F2", color: "text.primary", }} >
      <ToolbarMUI sx={{ justifyContent: "space-between" }}>
        <Filter />
        <LayoutSwitcher />
        <Sorter />
      </ToolbarMUI>
    </AppBar>
  );
};

const Filter = () => {
  const [, toggleDrawer] = useAtom(toggle_drawer);

  const handleClick = () => {
    // TODO: Niklas - Handle Filter
    toggleDrawer("filter");
  };

  return (
    <Button
      color="inherit"
      startIcon={<Icon icon="filter" />}
      onClick={handleClick}
      sx={{
        fontWeight: 400,
        fontSize: "1rem",
      }}
    >
      Filter
    </Button>
  );
};

const LayoutSwitcher = () => {
  
  const [isGrid, setIsGrid] = useAtom(fav_grid);
  const handleClickList     = () => { setIsGrid(false); };
  const handleClickGrid     = () => { setIsGrid(true); };

  return (
    <Stack direction="row" spacing={0}>
      <IconButton onClick={handleClickList} color="inherit" sx={{ opacity: !isGrid ? 1 : 0.4 }} >
        <Icon icon="list" />
      </IconButton>
      <IconButton onClick={handleClickGrid} color="inherit" sx={{ opacity: isGrid ? 1 : 0.4 }} >
        <Icon icon="grid" sx={{ fontSize: "0.875em" }} />
      </IconButton>
    </Stack>
  );
};

const Sorter = () => {
  const dropdownRef = useRef(null);

  const options = getProjectData('sorteroptions',0,[])
  const active = getProjectData('activesorting')
  const units_ = getProjectData('units')
  const raws_ = getProjectData('unitsraw')

  const setSort = setProjectData('activesorting')
  const setUnits = setProjectData('units')
  const setRaws = setProjectData('unitsraw')

  const id = active.id
  const { Units, ...rest } = units_
  const { Units: Raws, ...wrest } = raws_

  const handleSelect = ({ID, Label, OrderOn, DefaultDirection}) => {

    const sorting = {
      key: OrderOn,
      direction: DefaultDirection,
      id: ID,
      label: Label,
    }

    const units = applysorting(Units, sorting)
    const raws = applysorting(Raws, sorting)

    setSort(sorting)
    setUnits({ ...rest, Units: units })
    setRaws({ ...wrest, Units: raws })
    
    dropdownRef.current.closeMenu();
  };

  return (
    <>
      <Dropdown ref={dropdownRef} buttonText="Sorter">
        {/* TODO: Niklas - handle sort options */}
        { options.map((option, i) => (
          <DropdownItem
            key={i}
            onClick={() => handleSelect(option)}
            selected={option.ID === id}
          >
            {option.Label}
          </DropdownItem>
        ))}
      </Dropdown>
    </>
  );
};

export default Toolbar;
