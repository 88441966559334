import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Address from "./Address";
import Buttons from "./Buttons";
import CTA from "./CTA";
import Description from "./Description";
import Downloads from "./Downloads";
import { FloorplansModalButton } from "./FloorplansModal";
import Footer from "./Footer";
import Gallery from "./Gallery";
import Highlights from "./Highlights";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import Price from "./Price";
import Properties from "./Properties";
import RelatedUnits from "./RelatedUnits";
import SignUpForm from "./SignUpForm";
import Statistics from "./Statistics";
import Unit from "./Unit.model";
import { get } from "components/Favorites/Favorites.Utilities";

const UnitPageDesktop = ({ unit }) => {

  // console.log(unit,"unnit")

  const unitId = unit.getId();
  const images = unit.getImages();
  const status = unit.getStatus();
  const highlights = useUnitHighlight(unit);
  const isRent = unit.isRent();
  const price = unit.getPrice();
  const Pris = unit.getPrice();

// console.log(Pris,"pris")

  const properties = unit.getProperties();
  const descTitle = unit.getDescriptionTitle();
  const description = unit.getDescription();
  const areaDescTitle = unit.getAreaDescriptionTitle();
  const areaDescription = unit.getAreaDescription();

  const priceText = unit.getPriceText();
  const availableUnits = unit?.data?.Data?.UnitStatusID?.Value === 10;
  const availablePris = unit?.data?.Data?.Pris_Tekst_DK?.Value === "";

  const unitImage = images[2]?.url || "";
  const areaImage = images[8]?.url || "";

  const filteredProperties = availablePris
    ? properties
    : properties.filter((property) => property?.order !== 20);

    // console.log(filteredProperties ,"order")
  return (
    <Box
      sx={{
        pb: 0,
      }}
    >
      <Grid container alignItems="stretch">
        {/* gallery */}
        <Grid
          item
          md={8.5}
          sx={{ backgroundColor: "#dddddd", position: "relative" }}
        >
          <Gallery images={images.slice(1)} hideThumbs fullHeight />

          <Box
            sx={{
              minWidth: 421,
              position: "absolute",
              bottom: 0,
              left: 0,
              zIndex: 5,
            }}
          >
            <Buttons unitId={unitId} />
          </Box>
          <Box sx={{ position: "absolute", bottom: 0, right: 0, zIndex: 5 }}>
            <FloorplansModalButton unit={unit} />
          </Box>
        </Grid>

        {/* overview */}
        <Grid item md={3.5}>
          {/* status bar */}
          <Box
            sx={{
              py: 2,
              px: 2.5,
              backgroundColor: `${status.color}e6`,
              color: "#ffffff",
              fontWeight: "bold",
            }}
          >
            {status.label}
          </Box>

          <OpenHouse />

          <Box sx={{ px: 2.5, pb: 5, mt: 3 }}>
            <Address>{unit.getAddress()}</Address>

            <Box sx={{ mt: 5, mx: "auto", maxWidth: 320 }}>
              <Highlights items={highlights} />
            </Box>

            <Box sx={{ mt: 4, textAlign: "center" }}>
              <Price
                  label={availablePris && (
                    isRent
                      ? `${get(unit.data, "LejelejlighedsText")}`
                      : `Kontantpris`)
                  }
                  value={(availablePris && price) ? price : ""}
              />
            </Box>

            <Box sx={{ mt: 4 }}>
              <CTA />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems="stretch">
        {/* properties */}
        <Grid item md={3.5}>
          <Box
            sx={{
              px: { xl: 6, lg: 5, md: 3 },
              py: { xl: 7, lg: 6, md: 5 },
              borderRight: "1px solid rgba(0,0,0,0.05)",
            }}
          >
            {/* <Properties groups={filteredProperties} priceText={priceText} /> */}
            <Properties groups={filteredProperties} />
            <Downloads />
          </Box>
        </Grid>

        {/* content */}
        <Grid item md={8.5}>
          <Grid container sx={{ backgroundColor: "#f3f3f3" }}>
            <Grid item md={7.065}>
              <Box p={6}>
                <Description
                  header={descTitle}
                  content={description}
                  overlayColor="#f3f3f3"
                />
              </Box>
            </Grid>
            <Grid item md={4.925}>
              {unitImage && (
                <Box
                  component="img"
                  src={unitImage}
                  sx={{ height: "100%", objectFit: "cover" }}
                />
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={4.925}>
              {areaImage && (
                <Box
                  component="img"
                  src={areaImage}
                  sx={{ height: "100%", objectFit: "cover" }}
                />
              )}
            </Grid>
            <Grid item md={7.065}>
              <Box p={6}>
                <Description header={areaDescTitle} content={areaDescription} />
              </Box>
            </Grid>
          </Grid>
          <Box mb={6}>
            <Statistics unit={unit} />
          </Box>

          <Box>
            <RelatedUnits unit={unit} />
          </Box>
        </Grid>
      </Grid>

      <div style={{ height: 30 }} />

      {/* FOOTER */}
      <Grid container sx={{ backgroundColor: "secondary.main", pb: 3 }}>
        <Grid item md={6} sx={{ backgroundColor: "secondary.main" }}>
          <SignUpForm unit={unit} />
        </Grid>
        <Grid item md={6} sx={{ backgroundColor: "#ffffff", pb: 5 }}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};

UnitPageDesktop.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default UnitPageDesktop;
