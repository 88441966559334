import { Box, Grid, Typography } from "@mui/material";
import { STATICS } from "api/api";
import React from "react";

function Footer() {
  return (
    <div>
      <Box
        component="img"
        src={`${STATICS}photo.jpg`}
        alt=""
        sx={{ width: "100%", height: { lg: 504, xs: 260 }, objectFit: "cover" }}
      />

      <Box sx={{ mt: 4, pb: 55, px: { md: 5, xs: 4 }, py: { md: 4, xs: 2 }, lineHeight: 1.4, }} >
        <Box sx={{ fontWeight: 700, fontSize: 20, mb: 4, }} >
          Har du spørgsmål?
        </Box>

        <Grid container sx={{ mb: 5 }} spacing={3}>
          {/* <Grid item lg={6} xs={12}>
            <Box>
              <strong>Lokal mægler</strong> <br />
              LokalBolig Silkeborg <br />
              Søtorvet 20 <br />
              8600 Silkeborg <br />
              <Typography component="a" href="mailto:soetorvet@lokalbolig.dk" > soetorvet@lokalbolig.dk </Typography> <br />
              88 44 22 11
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ textAlign: { lg: "center", xs: "left" }, }} >
            <Box component="img" src={`${STATICS}realtor_1.svg`} />
          </Grid> */}
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Box>
              <strong>Projekt mægler</strong> <br />
              LokalBolig Projekt <br />
              Strandvejen 62F <br />
              2900 Hellerup <br />
              <Typography component="a" href="mailto:soetorvet@lokalbolig.dk"> soetorvet@lokalbolig.dk</Typography> <br />
              32 53 22 22 <br />
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ textAlign: { lg: "center", xs: "left"} }} >
            <Box component="img" src={`${STATICS}realtor_2.svg`} />
          </Grid>
        </Grid>
        <div style={{height:80}}/>
      </Box>
    </div>
  );
}

export default Footer;
