import { useState } from 'react'
import { motion } from 'framer-motion'
import { AnimatePresence } from 'framer-motion'
import { Menu, Tile, Split, Text } from './Nav.Desktop.Foldout.style'
import { Icon } from 'assets/Icons_'
import { Link } from 'react-router-dom'
import { MENU } from 'constants'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useTranslation } from 'react-i18next'
export const Foldout = ({ nav, show, setShow }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const outside = { onTriggered: () => setShow(!show) }
  const ref = useDetectClickOutside(outside)

  const menu = {
    initial: { x: -MENU.DESKTOP_NAV_DRAWER_W + 'px' },
    animate: { x: 100 + 'px' },
    exit: { x: -MENU.DESKTOP_NAV_DRAWER_W + 'px' },
    transition: { duration: 0.3, ease: 'linear' },
  }

  return (
    <AnimatePresence>
      {show && (
        <Menu
          as={motion.div}
          clr={clr}
          {...menu}
          // ref={ref}
        >
          <div style={{ flex: 1 }} />
          {nav.map(({ url, text, icon }, j) => {
            const hrf = url[language]
            const ico = { icon, size: 45, color: clr.icons }

            return (
              <div key={text[language]}>
                {j > 0 && <Split clr={clr} />}
                <Link
                  to={hrf}
                  key={text[language]}
                  style={{ textDecoration: 'none' }}
                >
                  <Tile clr={clr}>
                    <Icon {...ico} />
                    <Text>{text[language]}</Text>
                  </Tile>
                </Link>
              </div>
            )
          })}
          <div style={{ flex: 1 }} />
        </Menu>
      )}
    </AnimatePresence>
  )
}
