import { Box, Divider, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import ContactForm from "./ContactFormMailChimp";
import { useContactDrawer } from "./useContactDrawer";

const ContactDrawer = () => {
  const { isOpen, toggle, unit } = useContactDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Drawer anchor={isMobile ? "bottom" : "right"} open={isOpen} onClose={() => toggle()} PaperProps={{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contastText", backgroundColor: "secondary.main", }, }} >
      <Box sx={{ width: isMobile ? "auto" : 422, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3, }} >
        <IconButton onClick={toggle} sx={{ position: "absolute", right: 16, top: 16, }} >
          <Icon icon="close" sx={{ fontSize: 18, }} />
        </IconButton>
        <Box sx={{ mt: 3, lineHeight: 1.5, }} >
          <Box sx={{ fontWeight: 700, mb: 1 }}>Lokal mægler</Box>
          <Box mb={2}>
            LokalBolig Silkeborg <br />
            Søtorvet 20
            8600 Silkeborg <br />
            soetorvet@lokalbolig.dk <br />
            88 44 22 11
          </Box>
          <Box sx={{ fontWeight: 700, mb: 1 }}>Projekt mægler</Box>
          <Box mb={2}>
            LokalBolig projekt <br />
            Strandvejen 62F
            2900 Hellerup <br />
            soetorvet@lokalbolig.dk <br />
            32 53 22 22
          </Box>
        </Box>
        <Divider sx={{ my: 4, borderColor: "#FFF", }}
        />

        {/* <ContactForm unit={unit}>
          <Box
            sx={{
              fontWeight: 700,
              lineHeight: 1.4,
              mb: 3,
            }}
          >
            Udfyld formularen og kom først i køen og bliv inviteret til
            premieren i UNITY Aarhus
          </Box>
        </ContactForm> */}
      </Box>
    </Drawer>
  );
};

export default ContactDrawer;
