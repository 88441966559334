import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";

function Description({ header, content, overlayColor }) {
  const [isExpand, setIsExpand] = useState(false);
  const [ref, { height }] = useMeasure();

  const maxHeight = 250;
  const expandable = maxHeight < height;

  const style = useSpring({
    height: isExpand ? height : maxHeight,
    overflow: "hidden",
    config: { duration: 200 },
  });

  const handleToggle = () => {
    setIsExpand((v) => !v);
  };

  return (
    <Box
      sx={{
        position: "relative",
        fontSize: "16px",
        pb: expandable ? "42px" : 0,
      }}
    >
      <animated.div style={style}>
        <Box ref={ref}>
          <Typography
            variant="h3"
            sx={{ fontSize: "20px", fontWeight: 700, mb: "24px" }}
          >
            {header}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Box>
      </animated.div>
      {expandable && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "absolute",
            bottom: -10,
            left: 0,
            width: "100%",
            height: "150px",
          }}
        >
          {!isExpand && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                background: `linear-gradient(0deg, ${overlayColor} 40%, ${overlayColor}00 100%)`,
              }}
            ></Box>
          )}
          <Button
            onClick={handleToggle}
            startIcon={isExpand ? <RemoveCircleOutlineOutlinedIcon/> : <AddCircleOutlineOutlinedIcon /> }
            color="inherit"
            sx={{
              fontSize: 16,
              ".MuiButton-startIcon > svg": {
                fontSize: 30,
              },
            }}
          >
            {isExpand ? "Læs mindre" : "Læs mere"}
          </Button>
        </Box>
      )}
    </Box>
  );
}

Description.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  overlayColor: PropTypes.string,
};

Description.defaultProps = {
  header: "",
  content: "",
  overlayColor: "#ffffff",
};

export default Description;
