import styled from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 100px;
  height: 59px;
  /* width: calc(100% - ${MENU.DESKTOP_NAV_W}px); */
  background: ${({ clr }) => clr?.primary};
  ${BREAK_.md_up} {
    z-index: 5; // need this to cover sidebar and footer with overlay
    margin-left: ${({ nav, left }) =>
      (nav && MENU.DESKTOP_NAV_DRAWER_W) +
      (left && MENU.DESKTOP_FILTER_DRAWER_W)}px;
    width: ${({ nav, left, right }) =>
      `calc(100% - ${
        MENU.DESKTOP_NAV_W +
        (nav && MENU.DESKTOP_NAV_DRAWER_W) +
        (left && MENU.DESKTOP_FILTER_DRAWER_W) +
        (right && MENU.DESKTOP_UNIT_DRAWER_W)
      }px)`};
  }
  ${BREAK_.md_dn} {
    background: #f9f9f9;
    top: 60px;
    left: 0;
    width: 100%;
  }
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
  > span {
    display: flex;
    flex: 1;
    border: 0px solid black;
  }
  > span:nth-child(1) {
    justify-content: flex-start;
  }
  > span:nth-child(2) {
    justify-content: center;
  }
  > span:nth-child(3) {
    justify-content: flex-end;
  }
  transition: padding-left 0.3s ease, padding-right 0.3s ease,
    margin-top 0.3s ease, margin-left 0.3s ease, height 0.3s ease,
    width 0.3s ease;
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.125rem;
  gap: 8px;
  padding:0 20px; 
  color:#fff;
  ${BREAK_.md_dn} {
    color: #000;
  }
`