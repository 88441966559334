import { Box, Grid } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";

function Highlights({ items }) {
  return (
    <Grid container spacing={2}>
      {items.map((item, i) => (
        <Grid item key={i} xs={6}>
          <HighlightItem item={item} />
        </Grid>
      ))}
    </Grid>
  );
}

Highlights.propTypes = {
  items: PropTypes.array.isRequired,
};

function HighlightItem({ item }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        lineHeight: 1.3,
      }}
    >
      <Box
        sx={{
          width: 45,
          flex: "0 0 45px",
        }}
      >
        <Icon
          icon={item.icon}
          sx={{
            fontSize: 30,
          }}
        />
      </Box>
      <div>
        <div>{item.name}</div>
        <strong>
          {item.value} {item.postfix}
        </strong>
      </div>
    </Box>
  );
}

HighlightItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Highlights;
