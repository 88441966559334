import styled from 'styled-components/macro'
import { Icon } from 'assets/Icons_'
import { iso_free, iso_isrotating } from 'state/store.global'
import { useGet, useSet } from 'state/jotai'
import { useBreak } from 'hooks/useBreak'
import { BREAK_ } from 'constants'

export const Mode360 = () => {

  const rmode        = useGet(iso_free)
  const setRmode     = useSet(iso_free)
  const isrotate     = useGet(iso_isrotating)
  const setIsrotate  = useSet(iso_isrotating)
  const isMobile     = useBreak('md_dn')

  const click1 = () => setIsrotate(false)
  const click2 = () => {
    setRmode(true)
    setIsrotate(true)
  }
  
  return (
    <>
      <Box>
       { 
        rmode && isrotate ? <>
          {isMobile ? <Text>Pause</Text> : <Text>Pause rotation</Text>}
          <Icon click={click1} icon='pause' size={isMobile?'25':'32'} color={clr.primary_text}/>
        </> : <>
          {isMobile ? <Text>360&#x00B0;</Text> : <Text>Afspil 360&#x00B0; rotation</Text>}
          <Icon click={click2} icon='play'  size={isMobile?'25':'32'} color={clr.primary_text}/>
        </>
       }
      </Box>
    </>
  )

}
const Text = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400px;  
  /* border: 1px solid black; */
`
const Box = styled.div`
  gap: 5px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 5;
  align-items: center;
  display: flex;
  ${ BREAK_.md_up } {
    position: absolute;
    top: 100px;
    right: 40px;
    padding:0;
  }
`