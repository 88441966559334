import { useAtom }     from 'state/jotai'
import { open_drawer } from 'state/actions'
import { active_unit } from 'state/store.global'
import { _project }    from 'state/store.projects'

export const useClick = (units) => {
  const draw  = useAtom(open_drawer)[1]
  const geta  = useAtom(active_unit)[0]
  const seta  = useAtom(active_unit)[1]
  return (clicked_UnitID) => {
    const Unit = units?.find( i => i.UnitID == clicked_UnitID )
    const sID  = Unit.Data.UnitStatusID.Value
    // if ( sID == 40 || sID == 30 ) { return }
    seta( Unit )
    draw('detail')
    log({ 
      action:'clicked_apartment_on_listview',
      unitId:Unit.UnitID
    })
    // clog( Unit.UnitID )
    // console.log( 'Unit: ==> ', clicked_UnitID, 'statusID: ', sID )
  }
}

export const click = id => {
  console.log('Clicked UnitID: ', id)
}
